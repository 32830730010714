<template>
  <div class="p-1">
    <div class="d-flex flex-column">
      <b-form-textarea
        v-model="form.multiLineSearchTerm"
        placeholder="Search here"
        autofocus
        @input="checkContent"
        @focus="$event.target.select()"
        @keydown.enter.native="multiSearch"
        @keydown.esc.native="closeModal"
      />

      <div
        class="position-absolute"
        style="right: 2em; top: 2em"
      >
        <b-icon
          v-if="isLoading"
          size="md"
          icon="arrow-clockwise"
          animation="spin"
        />

      </div>

      <b-button
        v-if="!v$.form.$invalid"
        class="mt-1"
        @click="multiSearch"
      >
        Multi Search
      </b-button>

      <div
        v-if="searched"
      >
        <template v-if="searched">
          <div class="p-1">
            <b-row>
              <b-col class="p-1 border">
                <h5 class="text-center">
                  Not Found
                </h5><h1 class="text-center">
                  {{ multiSearchNotFoundResult.length }}
                </h1>
                <b-button
                  block
                  size="sm"
                  variant="link"
                  @click="copySelectedArray(multiSearchNotFoundResult)"
                >
                  Copy "Not Found"
                </b-button>
              </b-col>
              <b-col class="p-1 border">
                <h5 class="text-center">
                  Success
                </h5><h1 class="text-center">
                  {{ multiSearchSuccessResult.length }}
                </h1>

                <b-button
                  block
                  size="sm"
                  variant="link"
                  @click="copySelectedArray(multiSearchSuccessResult)"
                >
                  Copy Success
                </b-button>
              </b-col>
              <b-col class="p-1 border">
                <h5 class="text-center">
                  Pending
                </h5><h1 class="text-center">
                  {{ multiSearchPendingResult.length }}
                </h1>

                <b-button
                  block
                  size="sm"
                  variant="link"
                  @click="copySelectedArray(multiSearchPendingResult)"
                >
                  Copy Pending
                </b-button>
              </b-col>
              <b-col class="p-1 border">
                <h5 class="text-center">
                  Failed
                </h5><h1 class="text-center">
                  {{ multiSearchFailedResult.length }}
                </h1>

                <b-button
                  block
                  size="sm"
                  variant="link"
                  @click="copySelectedArray(multiSearchFailedResult)"
                >
                  Copy Failed
                </b-button>
              </b-col>
            </b-row>
          </div>
          <!--          <template v-if="multiSearchResult.length >0">-->
          <!--            <template v-for="(result, ix) in multiSearchResult">-->
          <!--              <div-->
          <!--                v-if="ix<1"-->
          <!--                :key="`msRes-${ix}`"-->
          <!--                class="p-1 my-1 border"-->
          <!--              >-->
          <!--                {{ result.external_invoice_ref }} <br>-->
          <!--                Status: {{ result.transaction_status }} <br>-->

          <!--                <template v-if="result.transaction_status === 'FAILED'">-->
          <!--                  Reason: {{ result.error_message.error_code }} - {{ humanise(result.error_message.error_message) }}-->
          <!--                </template>-->
          <!--              </div>-->
          <!--            </template>-->
          <!--          </template>-->
          <!--          <template v-else>-->
          <!--            No Result Found-->
          <!--          </template>-->

        </template>
      </div>
      <div class="d-flex">

        <b-button
          v-if="!v$.form.$invalid"
          block
          :disabled="!multiSearchResult.length> 0 && !multiSearchNotFoundResult.length > 0"
          class="mt-1"
          variant="primary"
          @click="processResultArray()"
        >
          Copy All
        </b-button>
        <b-button
          v-if="!v$.form.$invalid"
          block
          :disabled="!successResultTxns.length> 0"
          class="mt-1 ml-1"
          @click="bulkCallback(contentSearchTerm)"
        >
          Callbacks
        </b-button>

      </div>
    </div>

  </div>
</template>

<script>

import vSelect from 'vue-select'
import { useVuelidate } from '@vuelidate/core'
import { minLength, required } from '@vuelidate/validators'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BIcon, BIconArrowClockwise } from 'bootstrap-vue'
import humaniseMixin from '@/common/humanise.mixin'

export default {
  components: { vSelect, BIcon, BIconArrowClockwise },
  mixins: [humaniseMixin],
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      form: {
        multiLineSearchTerm: { required, minLength: minLength(7) },
      },
    }
  },
  data() {
    return {
      isLoading: false,
      form: {
        multiLineSearchTerm: '',
      },
      searched: false,
      searchResult: null,
      contentSearchTerm: [],
      multiSearchResult: [],
      multiSearchSuccessResult: [],
      multiSearchNotFoundResult: [],
      multiSearchPendingResult: [],
      multiSearchFailedResult: [],
      copyArray: [],
      resultType: null,
      psStatusCheckData: [],
    }
  },
  computed: {
    successResultTxns() {
      if (this.multiSearchSuccessResult.length > 0) {
        return this.multiSearchSuccessResult.map(item => {
          if (item.state === 'Transaction') {
            return item.invoice_ref
          } return item.external_invoice_ref
        })
      } return []
    },
  },
  async mounted() {

  },
  methods: {
    copyBlock(item) {
      navigator.clipboard.writeText(JSON.stringify(item, undefined, 2))

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Raw Copied',
          icon: 'BellIcon',
        },
      })
    },
    canSplit(str, token) {
      return (str || '').split(token).length > 1
    },
    copyNoResult(multiLineSearchTerm) {
      const clipboardContent = `${multiLineSearchTerm}\nStatus: Transaction not found\n\n`

      navigator.clipboard.writeText(clipboardContent)

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Telegram Text Copied',
          icon: 'BellIcon',
        },
      })
    },
    copyCardContent(obj, buildCopyArray = false) {
      console.log('obj', obj)
      const errorMessageObj = obj.error_message

      const txnType = obj.state
      let txnStatus = txnType
      let invoiceRef

      if (txnType === 'Transaction') {
        txnStatus = obj.invoice_status
        invoiceRef = obj.invoice_ref
      } else if (txnType === 'Payout') {
        txnStatus = obj.transaction_status
        invoiceRef = obj.external_invoice_ref
      } else if (txnType === 'NOT_FOUND') {
        txnStatus = 'NOT_FOUND'
        invoiceRef = obj.searchedInvoice
      }

      let clipboardContent = ''

      if (txnStatus === 'FAILED') {
        if (txnType === 'Transaction') {
          const debitStatus = obj.fpx_debit_status
          const debitAuthCode = obj.fpx_debit_auth_code

          if (debitStatus && debitAuthCode) {
            clipboardContent = `${invoiceRef}\nStatus: ${txnStatus}\nReason: ${debitAuthCode} -  ${debitStatus}\n\n`
          } else {
            clipboardContent = `${invoiceRef}\nStatus: ${txnStatus}\nReason: Unknown\n\n`
          }

          console.log(clipboardContent)

          if (buildCopyArray) {
            this.copyArray.push(clipboardContent)
          }
          navigator.clipboard.writeText(clipboardContent)
        } else if (txnType === 'Payout') {
          const msg = errorMessageObj.error_message
          if (this.canSplit(msg, '|')) {
            clipboardContent = `${invoiceRef}\nStatus: ${txnStatus}\nReason: ${errorMessageObj.error_code} - ${this.translateText(errorMessageObj.error_message.split('|')[2])}\n\n`

            console.log(clipboardContent)
            // this.doCopy(clipboardContent, 'Telegram Message')
            if (buildCopyArray) {
              this.copyArray.push(clipboardContent)
            }
            navigator.clipboard.writeText(clipboardContent)
          } else {
            clipboardContent = `${invoiceRef}\nStatus: ${txnStatus}\nReason: ${errorMessageObj.error_code} - ${this.translateText(errorMessageObj.error_message)}\n\n`

            console.log(clipboardContent)
            // this.doCopy(clipboardContent, 'Telegram Message')
            if (buildCopyArray) {
              this.copyArray.push(clipboardContent)
            }
            navigator.clipboard.writeText(clipboardContent)
          }
        } else {
          if (txnType === 'Transaction') {
            clipboardContent = `${invoiceRef}\nStatus: ${txnStatus}\nReason: - \n\n`
          } else if (txnType === 'Tayout') {
            clipboardContent = `${invoiceRef}\nStatus: ${txnStatus}\nReason: ${this.translateText(errorMessageObj.description)}\n\n`
          }

          clipboardContent = `${invoiceRef}\nStatus: ${txnStatus}\nReason: ${this.translateText(errorMessageObj.description)}\n\n`
          console.log(clipboardContent)

          if (buildCopyArray) {
            this.copyArray.push(clipboardContent)
          }
          navigator.clipboard.writeText(clipboardContent)
        }
      }
      if (txnStatus === 'PENDING') {
        clipboardContent = `${invoiceRef}\nStatus: ${txnStatus}\n\n`
        // this.doCopy(clipboardContent, 'Telegram Message')

        if (buildCopyArray) {
          this.copyArray.push(clipboardContent)
        }
        navigator.clipboard.writeText(clipboardContent)
      }
      if (txnStatus === 'SUCCESS') {
        clipboardContent = `${invoiceRef}\nStatus: ${txnStatus}\n\n`
        // this.doCopy(clipboardContent, 'Telegram Message')
        if (buildCopyArray) {
          this.copyArray.push(clipboardContent)
        }
        navigator.clipboard.writeText(clipboardContent)
      }
      if (txnStatus === 'NOT_FOUND') {
        clipboardContent = `${invoiceRef}\nStatus: Transaction not found\n\n`
        // this.doCopy(clipboardContent, 'Telegram Message')
        if (buildCopyArray) {
          this.copyArray.push(clipboardContent)
        }
        navigator.clipboard.writeText(clipboardContent)
      }

      if (!buildCopyArray) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Telegram Text Copied',
            icon: 'BellIcon',
          },
        })
      }
    },
    translateText(str) {
      let newText = ''
      switch (str) {
        case 'confident level name low than threshold':
          newText = 'Confident level name low than threshold'
          break

        case 'account verification failed please try again':
          newText = 'Account verification failed'
          break

        case ' Cause: Beneficiary bank’s technical problem/ error.':
          newText = 'Beneficiary bank’s technical problem/ error.'
          break

        case ' Cause: Transaction rejection by Paynet.':
          newText = 'Transaction rejection by Paynet.'
          break

        case ' Cause: Uncaught Cause':
          newText = 'Uncaught Cause'
          break

        case 'DUPLICATE_PAYOUT_TRANSACTION':
          newText = 'Duplicate payout transaction'
          break

        case 'duplicate transaction':
          newText = 'Duplicate transaction'
          break

        case 'UNABLE_TO_GET_TOKEN_ACCESS':
          newText = 'Unable to get access token'
          break

        default:
          newText = str
          break
      }
      console.log(newText)
      return newText
    },
    triggerCallbackData(invoiceNo) {
      console.log(invoiceNo)

      const payload = {
        invoice_nos: [invoiceNo],
      }

      this.$store.dispatch('general/omniCallbackBulkCallback', payload).then(res => {
        if (res.data.response_code === 2000) {
          this.$swal.fire({
            title: 'Successfully Triggered',
            text: 'Callback Triggered',
            icon: 'success',
          })
        } else {
          const desc = `<h5>Description</h5><p>${res.data.description}</p><h5>Breakdown</h5><p>${res.data.breakdown_errors}</p>`
          this.$swal.fire({
            title: `Error - ${res.data.response_code}`,
            html: desc,
            icon: 'error',
          })
        }
      })
    },
    getPSTxnStatus(txnInvoiceNo) {
      // console.log(txnInvoiceNo)
      this.isLoadingPSTxn = true
      const payload = {
        transaction_invoice_no: txnInvoiceNo,
      }

      this.psStatusCheckData = null
      this.$store.dispatch('adminCustomerBills/fpxFailedReason', payload).then(res => {
        if (res.data.response_code === 2000) {
          // this.psStatusCheckData = {
          //   invoice_status_id: customerBills.invoice_status_id,
          //   invoice_ref: customerBills.invoice_ref,
          //   ...res.data.data,
          // }
          // this.copyAsTelegram(this.psStatusCheckData)
          // console.log(res.data)

          this.isLoadingPSTxn = false
        }
      })
    },
    isValidJSON(str) {
      try {
        JSON.parse(str)
        console.log(true)
        return true
      } catch (e) {
        console.log(false)
        return false
      }
    },
    async resetForm() {
      this.form.multiLineSearchTerm = 0
      this.searched = false
      this.searchResult = null
    },
    processResultArray(array = []) {
      this.copyArray = []

      // eslint-disable-next-line no-param-reassign
      array = [
        ...this.multiSearchNotFoundResult, ...this.multiSearchResult,
      ]

      console.log(Array.isArray(array))
      console.log(array.length > 0)
      if (Array.isArray(array) && array.length > 0) {
        array.forEach(item => {
          this.copyCardContent(item, true)
        })
      }
      const clipboardContent = this.copyArray.join('')
      navigator.clipboard.writeText(clipboardContent)

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Telegram Text Copied',
          icon: 'BellIcon',
        },
      })
    },
    copySelectedArray(array = []) {
      this.copyArray = []

      console.log(Array.isArray(array))
      console.log(array.length > 0)
      if (Array.isArray(array) && array.length > 0) {
        array.forEach(item => {
          this.copyCardContent(item, true)
        })
      }
      const clipboardContent = this.copyArray.join('')
      navigator.clipboard.writeText(clipboardContent)

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Telegram Text Copied',
          icon: 'BellIcon',
        },
      })
    },
    async singleSearch(item) {
      const payload = {
        invoice_no: item,
      }

      this.isLoading = true

      await this.$store.dispatch('general/omniCheckInvoice', payload).then(res => {
        console.log('omniCheckInvoice', res.data.data)
        this.searched = true

        if (res.data.response_code === 2000) {
          this.multiSearchResult.push(res.data.data[0])

          if (res.data.data[0].state === 'Transaction') {
            if (res.data.data[0].invoice_status === 'SUCCESS') {
              this.multiSearchSuccessResult.push(res.data.data[0])
            }
            if (res.data.data[0].invoice_status === 'PENDING') {
              this.multiSearchPendingResult.push(res.data.data[0])
            }
            if (res.data.data[0].invoice_status === 'FAILED') {
              this.multiSearchFailedResult.push(res.data.data[0])
            }
          }
          if (res.data.data[0].state === 'Payout') {
            if (res.data.data[0].transaction_status === 'SUCCESS') {
              this.multiSearchSuccessResult.push(res.data.data[0])
            }
            if (res.data.data[0].transaction_status === 'PENDING') {
              this.multiSearchPendingResult.push(res.data.data[0])
            }
            if (res.data.data[0].transaction_status === 'FAILED') {
              this.multiSearchFailedResult.push(res.data.data[0])
            }
          }
        }

        if (res.data.response_code === 10017) {
          const toUpper = item.toUpperCase()
          console.log(toUpper, toUpper.includes('PLEASE'))
          if (!toUpper.includes('PLEASE') && !toUpper.includes('HITEAM')) {
            this.multiSearchNotFoundResult.push({
              searchedInvoice: item,
              state: 'NOT_FOUND',
              error_message: {
                response_code: res.data.response_code,
                error_code: null,
                error_message: 'Transaction Not Found',
                description: 'NOT_FOUND',
                data: null,
              },
            })
          }
          console.log('multiSearchNotFoundResult', this.multiSearchNotFoundResult)
        }

        this.isLoading = false
      })
    },
    closeModal() {
      this.resetForm()
      this.$emit('escaped-click')
    },
    checkContent() {
      console.log(this.form.multiLineSearchTerm)
      const string = this.form.multiLineSearchTerm
      // eslint-disable-next-line no-shadow
      const splitLines = str => str.replaceAll(' ', '').split(/\r?\n/)

      this.contentSearchTerm = splitLines(string)
    },
    multiSearch() {
      this.multiSearchResult = []
      this.multiSearchSuccessResult = []
      this.multiSearchPendingResult = []
      this.multiSearchFailedResult = []
      this.multiSearchNotFoundResult = []
      if (!(this.contentSearchTerm.length > 0)) {
        return
      }

      console.log('contentSearchTerm', this.contentSearchTerm)

      this.contentSearchTerm.forEach(item => {
        this.singleSearch(item)
      })
    },
    bulkCallback(array) {
      if (!(array.length > 0)) {
        return
      }

      const payload = {
        invoice_nos: array,
      }

      this.$store.dispatch('general/omniCallbackBulkCallback', payload).then(res => {
        if (res.data.response_code === 2000) {
          this.$swal.fire({
            title: 'Successfully Triggered',
            text: 'Callback Triggered',
            icon: 'success',
          })
        } else {
          const desc = `<h5>Description</h5><p>${res.data.description}</p><h5>Breakdown</h5><p>${res.data.breakdown_errors}</p>`
          this.$swal.fire({
            title: `Error - ${res.data.response_code}`,
            html: desc,
            icon: 'error',
          })
        }
      })
    },
  },
}
</script>

<style>

</style>
