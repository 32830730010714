import dashboard from './dashboard'
import administrative from '@/navigation/vertical/administrative'
import crmadmin from '@/navigation/vertical/crm'
import siteOptions from '@/navigation/vertical/siteOptions'

import { vm } from '@/main'

let selectedMenu = administrative

if (vm.IS_CRM_PORTAL) {
  selectedMenu = crmadmin
}

export default [
  ...dashboard,
  ...selectedMenu,
  // ...siteOptions,
]
