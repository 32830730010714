export default [
  {
    title: 'Users',
    icon: 'UsersIcon',
    route: 'administration-users',
  },
  {
    title: 'Accounts',
    icon: 'GridIcon',
    route: 'administration-accounts',
  },
  {
    title: 'Onboarding',
    icon: 'UserPlusIcon',
    children: [
      {
        title: 'Local',
        icon: 'HexagonIcon',
        route: 'administration-onboardingv2-local',
      },
      {
        title: 'International',
        icon: 'HexagonIcon',
        route: 'administration-onboardingv2-oversea',
      },
      {
        title: 'Legacy',
        icon: 'HexagonIcon',
        route: 'administration-onboarding',
      },
      {
        title: 'Service Audit',
        icon: 'HexagonIcon',
        route: 'administration-onboardingv2-service-audit',
      },
    ],
  },
  {
    title: 'Transactions',
    icon: 'DollarSignIcon',
    children: [
      // {
      //   title: 'Payments',
      //   icon: 'HexagonIcon',
      //   route: 'administration-payments',
      // },
      {
        title: 'Payments',
        icon: 'HexagonIcon',
        route: 'administration-customer-bills',
      },
      {
        title: 'Payout',
        icon: 'HexagonIcon',
        route: 'administration-payout',
      },
    ],
  },
  {
    title: 'Cost Centre',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'Transaction',
        icon: 'HexagonIcon',
        route: { name: 'administration-cost-centre-txn-list', params: { type: 'all' } },
      },
      {
        title: 'Payout',
        icon: 'HexagonIcon',
        route: { name: 'administration-cost-centre-payout-list', params: { type: 'all' } },
      },
    ],
  },
  // {
  //   title: 'Banks',
  //   icon: 'DollarSignIcon',
  //   route: 'administration-banks',
  // },
  {
    title: 'Prefund',
    icon: 'UploadCloudIcon',
    route: 'administration-prefund',
  },
  {
    title: 'Referral',
    icon: 'DownloadIcon',
    children: [
      {
        title: 'Users',
        icon: 'HexagonIcon',
        route: 'administration-lxReferral-datatable',
      },
      {
        title: 'Payout',
        icon: 'HexagonIcon',
        route: 'administration-referral-payout',
      },
      {
        title: 'Order',
        icon: 'HexagonIcon',
        route: 'administration-lxReferral-orders-datatable',
      },
    ],
  },
  // {
  //   title: 'Referral Payout',
  //   icon: 'DownloadIcon',
  //   route: 'administration-referral-payout',
  // },
  // {
  //   title: 'Referral Order',
  //   icon: 'DownloadIcon',
  //   route: 'administration-lxReferral-orders-datatable',
  // },
  {
    title: 'Settlement',
    icon: 'ArchiveIcon',
    children: [
      {
        title: 'Transaction',
        icon: 'HexagonIcon',
        route: 'administration-settlement',
      },
      {
        title: 'Commissions',
        icon: 'HexagonIcon',
        route: 'administration-commissions',
      },
      {
        title: 'Self Settlement',
        icon: 'HexagonIcon',
        route: 'administration-self-settlement',
      },
    ],
  },

  {
    title: 'Referral Commissions',
    icon: 'ArchiveIcon',
    route: 'administration-commission-referral',
  },
  {
    title: 'Custom Plans',
    icon: 'ArchiveIcon',
    route: 'administration-custom-plans',
  },
  {
    title: 'Plans',
    icon: 'LayersIcon',
    route: 'administration-plans',
  },
  // {
  //   title: 'Domains',
  //   icon: 'GlobeIcon',
  //   route: 'administration-domains',
  // },
  {
    title: 'Audits',
    icon: 'ServerIcon',
    route: 'administration-audits',
  },
  {
    title: 'Maintenance',
    icon: 'SettingsIcon',
    route: 'administration-maintenance',
  },
  {
    title: 'Helpers',
    icon: 'ArchiveIcon',
    children: [
      {
        title: 'Withdrawal Tester',
        icon: 'HexagonIcon',
        route: 'admin-helper-payout-tester-page',
      },
      {
        title: 'Deposit Tester',
        icon: 'HexagonIcon',
        route: 'admin-helper-deposit-tester-page',
      },
    ],
  },
]
