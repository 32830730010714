<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="10"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0 text-capitalize">
            {{ $store.getters['breadcrumbs/altTitle'] || $store.getters['breadcrumbs/pageTitle'] }}
            <!--            {{ $store.getters['breadcrumbs/pageTitle'] || $store.getters['breadcrumbs/altTitle'] }}-->
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in breadcrumbData"
                :key="item.text"
                class="text-capitalize"
                :active="!item.route"
                :to="item.route"
              >
                {{ item.title }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <b-col
      class="content-header-right text-md-right mb-1"
      md="3"
      cols="2"
    >
      <b-dropdown
        variant="link"
        no-caret
        toggle-class="p-0"
        right
      >

        <template #button-content>
          <b-button
            variant="primary"
            class="btn-icon"
          >
            <feather-icon icon="SettingsIcon" />
          </b-button>
        </template>

        <b-dropdown-item @click="toggleModalShowStatus">
          <span class="align-middle ml-50">Check Bank Account</span>
        </b-dropdown-item>

        <b-dropdown-item
          v-if="IS_ADMIN_PORTAL"
          @click="openHelper('bills')"
        >
          <span class="align-middle ml-50">Bills Helper</span>
        </b-dropdown-item>

        <b-dropdown-item
          v-if="IS_ADMIN_PORTAL"
          @click="openHelper('payout')"
        >
          <span class="align-middle ml-50">Payout Helper</span>
        </b-dropdown-item>

        <b-dropdown-item
          v-if="IS_ADMIN_PORTAL"
          @click="openHelper('search')"
        >
          <span class="align-middle ml-50">Search</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if="IS_ADMIN_PORTAL"
          @click="openHelper('multiSearch')"
        >
          <span class="align-middle ml-50">Multi Search</span>
        </b-dropdown-item>

      </b-dropdown></b-col>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex'
import router from '@/router'

export default {
  directives: {
  },
  components: {
  },
  computed: {
    ...mapGetters(['breadcrumbs/pageTitle', 'breadcrumbs/pageSubtitle']),
    breadcrumbData() {
      return this.$store.getters['breadcrumbs/breadcrumbs']
    },
  },
  methods: {
    async toggleModalShowStatus() {
      const statusStore = await this.$store.getters['systemConstants/getCheckBankAccountModalStatus']
      const newStatus = !statusStore
      await this.$store.dispatch('systemConstants/toggleCheckBankAccountModal', newStatus)
    },
    async openHelper(type) {
      if (type === 'bills') {
        router.push({ name: 'admin-helper-bills-page' }).catch(() => {})
      }
      if (type === 'payout') {
        router.push({ name: 'admin-helper-payout-page' }).catch(() => {})
      }
      if (type === 'search') {
        await this.$store.dispatch('general/toggleOmniMultiSearchModal', false)
        await this.$store.dispatch('general/toggleOmniSearchModal', true)
      }
      if (type === 'multiSearch') {
        await this.$store.dispatch('general/toggleOmniSearchModal', false)
        await this.$store.dispatch('general/toggleOmniMultiSearchModal', true)
      }
    },
  },
}
</script>
