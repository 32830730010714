<template>
  <div>
    <b-form-group :class="[{ 'has-error': v$.form.client_identifier.$error }]">
      <label>Client Identifier</label>
      <b-form-input
        v-model="form.client_identifier"
        placeholder="Client Identifier"
        disabled
      />
      <template v-for="(error, idx) in v$.form.client_identifier.$errors">
        <p
          :key="'error-'+idx"
          class="small text-danger"
        >
          {{ error.$message }}
        </p>
      </template>
    </b-form-group>
    <b-form-group :class="[{ 'has-error': v$.form.verification_channel_id.$error }]">
      <label>Bank</label>
      <v-select
        v-model="form.verification_channel_id"
        :reduce="verification_channel_id => verification_channel_id.payout_service_id"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="name"
        placeholder="Please select"
        :options="VerificationChannelListOpts"
      />
      <template v-for="(error, idx) in v$.form.verification_channel_id.$errors">
        <p
          :key="'error-'+idx"
          class="small text-danger"
        >
          {{ error.$message }}
        </p>
      </template>
    </b-form-group>

    <b-form-group :class="[{ 'has-error': v$.form.third_party_account_no.$error }]">
      <label>3rd Party Account Number</label>
      <b-form-input
        v-model="form.third_party_account_no"
        placeholder="Account No"
      />
      <template v-for="(error, idx) in v$.form.third_party_account_no.$errors">
        <p
          :key="'error-'+idx"
          class="small text-danger"
        >
          {{ error.$message }}
        </p>
      </template>
    </b-form-group>

    <template v-if="result">
      <pre>{{ result }}</pre>
    </template>
  </div>
</template>

<script>

import vSelect from 'vue-select'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  components: { vSelect },

  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      form: {
        client_identifier: { required },
        verification_channel_id: { required },
        third_party_account_no: { required },
      },
    }
  },
  data() {
    return {
      loadedPayload: null,
      result: null,
      form: {
        client_identifier: 'LEANPAY',
        verification_channel_id: '',
        third_party_account_no: '',
      },
    }
  },
  computed: {
    VerificationChannelListOpts() {
      return this.$store.getters['general/verificationChannelListOpts']
    },
  },
  async mounted() {
    if (this.$store.state.general.verificationPayload) {
      const x = this.$store.state.general.verificationPayload
      this.form = {
        client_identifier: 'LEANPAY',
        verification_channel_id: parseInt(x.verification_channel_id, 10),
        third_party_account_no: x.third_party_account_no,
      }
    }
    await this.$store.dispatch('general/getListVerificationChannel')

    console.log('2', this.$store.state.systemConstants.checkBankAccountPayload)
    if (this.$store.state.systemConstants.checkBankAccountPayload) {
      const payload = this.$store.state.systemConstants.checkBankAccountPayload
      this.form = {
        client_identifier: 'LEANPAY',
        verification_channel_id: parseInt(payload.payout_service_id, 10),
        third_party_account_no: payload.payout_account_number,
      }
    }
  },
  methods: {
    async resetForm() {
      this.form = {
        client_identifier: 'LEANPAY',
        verification_channel_id: '',
        third_party_account_no: '',
      }

      await this.$store.dispatch('systemConstants/updateCheckBankAccountModal', null)
      this.v$.form.$reset()
    },
    async verifyBankAccount() {
      const valid = await this.v$.form.$validate()

      if (!valid) {
        alert(valid)
        return
      }

      const payload = {
        ...this.form,
      }
      await this.$store.dispatch('general/verifyBankAccount', payload).then(res => {
        if (res.data.response_code === 2000) {
          this.result = res.data.data
        }
      })
    },
  },
}
</script>

<style>

</style>
