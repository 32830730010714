<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <div v-if="IS_ADMIN_PORTAL">
        <b-row>
          <b-col cols="4">
            <p class="mb-0 small text-white">
              LeanX Virtual Account Balance

            </p>
            <b-input-group size="sm">
              <template #prepend>
                <b-input-group-text class="bg-secondary border-secondary text-white">
                  {{ myCurrency }}
                </b-input-group-text>
              </template>
              <b-form-input
                disabled
                style="font-size: 1.1rem; text-align: right"
                :value="virtualAccountBalance"
              />
              <b-input-group-append>
                <b-button
                  variant="warning"
                  @click="refreshVADetails"
                >
                  <feather-icon
                    icon="RefreshCcwIcon"
                    size="14"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col cols="4">
            <p class="mb-0 small text-white">
              Collection Pool Amount
            </p>
            <b-input-group size="sm">
              <template #prepend>
                <b-input-group-text class="bg-secondary border-secondary text-white">
                  {{ myCurrency }}
                </b-input-group-text>
              </template>
              <b-form-input
                disabled
                style="font-size: 1.1rem; text-align: right"
                :value="collectionPoolBalance"
              />
              <b-input-group-append>
                <b-button
                  variant="warning"
                  @click="refreshCollectionPool"
                >
                  <feather-icon
                    icon="RefreshCcwIcon"
                    size="14"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <b-col cols="4">
            <p class="mb-0 small text-white">
              Admin Payout Balance
            </p>
            <b-input-group size="sm">
              <template #prepend>
                <b-input-group-text class="bg-secondary border-secondary text-white">
                  {{ myCurrency }}
                </b-input-group-text>
              </template>
              <b-form-input
                disabled
                style="font-size: 1.1rem; text-align: right"
                :value="adminPrefundBalance"
              />
              <b-input-group-append>
                <b-button
                  variant="warning"
                  @click="refreshCollectionPool"
                >
                  <feather-icon
                    icon="RefreshCcwIcon"
                    size="14"
                  />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </div>

      <b-form-checkbox
        v-if="debugMode"
        v-model="toggleVal"
        class="d-block"
        checked="false"
        switch
        stacked
        @change="doToggleDebug"
      >
        <span class="text-white">Debug {{ $store.getters['auth/getDebugToggle'] }}</span>
      </b-form-checkbox>
    </div>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >

        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ CurrentUser[0].name }}
            </p>
            <span class="user-status">{{ humanise(CurrentUser[0].account_type) }}</span>
          </div>
          <b-avatar
            size="40"
            variant="secondary"
            :text="initials"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
        >
          <dark-Toggler class="d-none d-lg-block" />
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="$router.push({name: 'merchant-info'})"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <!--        <b-dropdown-item link-class="d-flex align-items-center">-->
        <!--          <feather-icon-->
        <!--            size="16"-->
        <!--            icon="MailIcon"-->
        <!--            class="mr-50"-->
        <!--          />-->
        <!--          <span>Inbox</span>-->
        <!--        </b-dropdown-item>-->

        <!--        <b-dropdown-item link-class="d-flex align-items-center">-->
        <!--          <feather-icon-->
        <!--            size="16"-->
        <!--            icon="CheckSquareIcon"-->
        <!--            class="mr-50"-->
        <!--          />-->
        <!--          <span>Task</span>-->
        <!--        </b-dropdown-item>-->

        <!--        <b-dropdown-item link-class="d-flex align-items-center">-->
        <!--          <feather-icon-->
        <!--            size="16"-->
        <!--            icon="MessageSquareIcon"-->
        <!--            class="mr-50"-->
        <!--          />-->
        <!--          <span>Chat</span>-->
        <!--        </b-dropdown-item>-->

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>

    <b-modal
      ref="checkBankAccountRef"
      v-model="$store.state.systemConstants.showBankAccountModal"
      size="md"
      header-bg-variant="primary"
      header-text-variant="light"
      title="Check Bank Account"
      no-close-on-esc
      no-close-on-backdrop
      ok-title="Check"
      @ok.prevent="$refs.checkBankAccountFormRef.verifyBankAccount()"
      @close="$refs.checkBankAccountFormRef.resetForm()"
      @cancel="$refs.checkBankAccountFormRef.resetForm()"
    >
      <check-bank-account-form ref="checkBankAccountFormRef" />
    </b-modal>

    <b-modal
      ref="omniSearchModalRef"
      v-model="$store.state.general.showOmniSearchModal"
      modal-class="omniSearch"
      size="lg"
      centered
      header-bg-variant="primary"
      header-text-variant="light"
      title="Search"
      ok-title="Check"
      hide-footer
      hide-header
    >
      <omni-search-form @escaped-click="closeModalOmni(false)" />
    </b-modal>

    <b-modal
      ref="omniMultiSearchModalRef"
      v-model="$store.state.general.showOmniMultiSearchModal"
      modal-class="omniSearch"
      size="lg"
      centered
      header-bg-variant="primary"
      header-text-variant="light"
      title="Multi Search"
      ok-title="Check"
      hide-footer
      hide-header
    >
      <omni-multi-search-form @escaped-click="closeModalOmni(false)" />
    </b-modal>
  </div>
</template>

<script>
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import humaniseMixin from '@/common/humanise.mixin'
import CheckBankAccountForm from '@/views/administration/general/components/checkBankAccountForm.vue'
import OmniSearchForm from '@/views/administration/general/components/omniSearchForm.vue'
import OmniMultiSearchForm from '@/views/administration/general/components/omniMultiSearchForm.vue'

export default {
  components: {
    OmniSearchForm,
    CheckBankAccountForm,
    OmniMultiSearchForm,
    // Navbar Components
    DarkToggler,
  },
  mixins: [humaniseMixin],
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      toggleVal: false,
    }
  },
  computed: {
    initials() {
      const user = this.$store.getters['accounts/getCurrentAccountMe']

      if (Array.isArray(user) && user.length > 0) {
        return user[0].name[0]
      }
      return 'U'
    },
    virtualAccountBalance() {
      const virtualAccountDetails = this.$store.getters['virtualAccounts/getAdminVirtualAccountDetails']
      if (virtualAccountDetails.ADMIN_DEFAULT_ACCOUNT) {
        return virtualAccountDetails.ADMIN_DEFAULT_ACCOUNT.balance
      }
      return '0.00'
    },
    collectionPoolBalance() {
      const collectionPoolDetails = this.$store.getters['collectionPool/getAdminCollectionPoolDetails']
      if (collectionPoolDetails && collectionPoolDetails.balance) {
        return this.numberWithCommas(parseFloat(collectionPoolDetails.balance).toFixed(2))
      }
      return '0.00'
    },
    adminPrefundBalance() {
      const adminPrefundBal = this.$store.getters['virtualAccounts/getAdminVirtualAccountDetails']
      if (adminPrefundBal.ADMIN_SETTLEMENT_DEFAULT_ACCOUNT) {
        return adminPrefundBal.ADMIN_SETTLEMENT_DEFAULT_ACCOUNT.balance
      }
      return '0.00'
    },
    CurrentUser() {
      const user = this.$store.getters['accounts/getCurrentAccountMe']
      // console.log('user', user)
      if (Array.isArray(user) && user.length > 0) {
        return user
      }
      return [{ name: 'User', account_type: 'User' }]
    },
  },
  created() {
    console.log('created')
    window.addEventListener('keydown', e => {
      if (e.ctrlKey && e.key === 'k') {
        e.preventDefault()
        console.log('search')
        this.toggleOmniMultiSearchModal(false)
        this.toggleOmniSearchModal(true)
      }

      if (e.ctrlKey && e.key === 'l') {
        e.preventDefault()
        console.log('search multi')
        this.toggleOmniSearchModal(false)
        this.toggleOmniMultiSearchModal(true)
      }

      // alert(e.ctrlKey)
    })
  },
  async mounted() {
    await this.$store.dispatch('auth/toggleDebug', this.toggleVal)
    const payload = {
      skip: 0,
      limit: 1,
    }
    await this.$store.dispatch('accounts/getAccountMe', payload)
    await this.$store.dispatch('virtualAccounts/getAdminVirtualAccountDetails')

    await this.$store.dispatch('collectionPool/getAdminCollectionPoolDetails')
  },
  methods: {
    async toggleOmniMultiSearchModal(flag = false) {
      await this.$store.dispatch('general/toggleOmniMultiSearchModal', flag)
    },
    async toggleOmniSearchModal(flag = false) {
      await this.$store.dispatch('general/toggleOmniSearchModal', flag)
    },
    async closeModalOmni(flag = false) {
      await this.$store.dispatch('general/toggleOmniSearchModal', flag)
    },
    refreshVADetails() {
      this.$store.dispatch('virtualAccounts/getAdminVirtualAccountDetails')
    },
    refreshCollectionPool() {
      this.$store.dispatch('collectionPool/getAdminCollectionPoolDetails')
    },

    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    doToggleDebug() {
      // alert(this.toggleVal)
      this.$store.dispatch('auth/toggleDebug', this.toggleVal)
    },
    logout() {
      console.log('auth logout')
      this.$store.dispatch('auth/logout')
    },

  },
}
</script>

<style lang="scss">
.omniSearch {
  .modal-body { padding: 0 !important;}
}
</style>
