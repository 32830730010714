<template>
  <b-nav-item
    class="dark-toggler p-0"
    @click="skin = isDark ? 'light' : 'dark'"
  >
    <feather-icon
      size="21"
      class="mr-1"
      :class="`${isDark ? 'feather-sun' : 'feather-moon'}`"
      :icon="`${isDark ? 'Sun' : 'Moon'}Icon`"
    />
    <div
      class="d-inline"
      :class="`${isDark ? 'feather-sun' : 'feather-moon'}`"
    >{{ isDark ? 'Light Theme' : 'Dark Theme' }}</div>
  </b-nav-item>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import { BNavItem } from 'bootstrap-vue'

export default {
  components: {
    BNavItem,
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return { skin, isDark }
  },
  methods: {
    toggleTheme() {
      this.isDark.value = !this.isDark.value
    },
  },
}
</script>
<style lang="scss">

.dark-toggler {
  a.nav-link { color: #FFF;
    padding: 0 !important;
  }
}
.feather-moon {
  stroke: #6e6b7b;
  color: #6e6b7b
}
.feather-sun {
  stroke: #b4b7bd;
  color: #b4b7bd
}
</style>
