export default [
  {
    title: 'Accounts',
    icon: 'GridIcon',
    route: 'administration-accounts',
  },
  {
    title: 'Payment',
    icon: 'FileTextIcon',
    route: 'administration-customer-bills',
  },
  {
    title: 'Payout',
    icon: 'ExternalLinkIcon',
    route: 'administration-payout',
  },
  {
    title: 'Helpers',
    icon: 'ArchiveIcon',
    children: [
      {
        title: 'Withdrawal Tester',
        icon: 'HexagonIcon',
        route: 'admin-helper-payout-tester-page',
      },
      {
        title: 'Deposit Tester',
        icon: 'HexagonIcon',
        route: 'admin-helper-deposit-tester-page',
      },
    ],
  },
]
