<template>
  <div class="p-1">
    <div class="d-flex">

      <b-input-group>

        <b-form-input
          v-model="form.searchTerm"
          placeholder="Search here"
          autofocus
          @focus="$event.target.select()"
          @keydown.enter.native="search"
          @keydown.esc.native="closeModal"
        />
        <b-input-group-append
          v-if="isLoading"
          is-text
        >
          <b-icon
            size="sm"
            icon="arrow-clockwise"
            animation="spin"
          />
        </b-input-group-append>

      </b-input-group>

      <b-button
        v-if="!v$.form.$invalid"
        class="ml-1"
        @click="search"
      >
        Search
      </b-button>
    </div>
    <!--    <pre>-&#45;&#45;{{ psStatusCheckData }}</pre>-->
    <template v-if="searched">
      <!--      <pre>-->
      <!--        {{ searchResult }}-->
      <!--      </pre>-->
      <template v-if="searchResult && searchResult.length > 0">
        <div class="d-flex justify-content-between">
          <h4 class="px-1 mt-3">
            Search Result for {{ form.searchTerm }}
          </h4>
          <h4
            v-if="isLoading"
            class="px-1 mt-3"
          >
            Searching...
          </h4>
        </div>
        <template v-for="(item, index) in searchResult">
          <div
            v-if="item.state === 'Payout'"
            :key="`adminSearchResultKey-${index}`"
            class="mt-2 border border-bottom-0"
          >
            <div class="p-1 position-relative">
              <b-button
                class="position-absolute position-top-0 position-right-0"
                size="sm"
                variant="link"
                @click="copyBlock(item)"
              >
                Copy Raw
              </b-button>

              TXN Type: {{ item.state }} <br>
              TXN Amount: <vue-numeric
                read-only
                :value="item.value"
                :currency="myCurrency"
                :precision="2"
              /> <br>
              TXN Fee: <vue-numeric
                read-only
                :value="item.transaction_fee"
                :currency="myCurrency"
                :precision="2"
              /> <br>
              TXN Invoice No: {{ item.transaction_invoice_no }} <br>

              Created At: {{ transformDate(item.created_at, false, 'FF' ) }} (<em>{{ transformDate(item.created_at, true, 'FF' ) }}</em>) <br>
              Updated At: {{ transformDate(item.updated_at, false, 'FF') }} (<em>{{ transformDate(item.updated_at, true, 'FF') }}</em>)<br>

              <template v-if="item.error_message.error_code === '97'">
                Confidence Level: {{ item.name_confident_level.confidence_level }} <br>
              </template>

            </div>
          </div>

          <div
            v-if="item.state === 'Payout'"
            :key="`searchResultKey-${index}`"
            class="border"
          >
            <div class="p-1 position-relative">
              External Invoice Ref: {{ item.external_invoice_ref }} <br>
              Status: {{ item.transaction_status }}<br>

              <template v-if="item.transaction_status === 'FAILED'">
                Reason: {{ item.error_message.error_code }} - {{ translateText(item.error_message.error_message) }}
              </template>
            </div>
            <div class="d-flex justify-content-end border-top p-1">
              <b-button
                class="ml-1"
                size="sm"
                variant="info"
                @click="copyCardContent(item, item.state)"
              >
                <feather-icon icon="CopyIcon" />
                Telegram
              </b-button>
              <b-button
                class="ml-1"
                size="sm"
                variant="secondary"
                @click="triggerCallbackData(item.transaction_invoice_no)"
              >
                Callback
              </b-button>
            </div>
          </div>

          <div
            v-if="item.state === 'Transaction'"
            :key="`txnSearchResultKey-${index}`"
            class="border border-bottom-0"
          >
            <div class="p-1 position-relative">
              <b-button
                class="position-absolute position-top-0 position-right-0"
                size="sm"
                variant="link"
                @click="copyBlock(item)"
              >
                Copy Raw
              </b-button>

              TXN Type: {{ item.state }} <br>
              TXN Amount: <vue-numeric
                read-only
                :value="item.total"
                :currency="myCurrency"
                :precision="2"
              /><br>
              TXN Fee: <vue-numeric
                read-only
                :value="item.amount_fee"
                :currency="myCurrency"
                :precision="2"
              /><br>
              TXN Invoice No: {{ item.transaction_invoice_no }} <br>

              Created At: {{ transformDate(item.created_at, false, 'FF' ) }} (<em>{{ transformDate(item.created_at, true, 'FF' ) }}</em>) <br>
              Updated At: {{ transformDate(item.updated_at, false, 'FF') }} (<em>{{ transformDate(item.updated_at, true, 'FF') }}</em>)<br>

            </div>
          </div>
          <div
            v-if="item.state === 'Transaction'"
            :key="`adminTxnSearchResultKey-${index}`"
            class="border mb-2"
          >
            <div class="p-1 position-relative">
              Invoice Ref: {{ item.invoice_ref }} <br>
              Status: {{ item.invoice_status }} <br>

              <template v-if="item.invoice_status === 'FAILED'">
                <template v-if="item.fpx_debit_auth_code && item.fpx_debit_status">

                  Reason: {{ item.fpx_debit_auth_code }} - {{ item.fpx_debit_status }}
                </template>
                <template v-else>
                  Reason: Unknown
                </template>
              </template>
            </div>
            <div class="d-flex justify-content-end border-top p-1">
              <b-button
                class="ml-1"
                size="sm"
                variant="info"
                @click="copyCardContent(item, item.state)"
              >
                <feather-icon icon="CopyIcon" />
                Telegram
              </b-button>
              <b-button
                class="ml-1"
                size="sm"
                variant="secondary"
                @click="triggerCallbackData(item.transaction_invoice_no)"
              >
                Callback
              </b-button>
            </div>
          </div>
        </template>
      </template>
      <template v-else>
        <div class="d-flex justify-content-between">
          <h4 class="px-1 mt-3">
            Search Result for {{ form.searchTerm }}
          </h4>
          <h4
            v-if="isLoading"
            class="px-1 mt-3"
          >
            Searching...
          </h4>
        </div>

        <div
          class="border"
        >

          <div class="p-1">
            {{ form.searchTerm }} <br>
            Status: Transaction not found
          </div>
          <div class="d-flex justify-content-end border-top p-1">
            <b-button
              class="ml-1"
              size="sm"
              variant="info"
              @click="copyNoResult(form.searchTerm)"
            >
              <feather-icon icon="CopyIcon" />
              Telegram
            </b-button>
          </div>
        </div>

      </template>
    </template>

  </div>
</template>

<script>

import vSelect from 'vue-select'
import { useVuelidate } from '@vuelidate/core'
import { minLength, required } from '@vuelidate/validators'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BIcon, BIconArrowClockwise } from 'bootstrap-vue'
import humaniseMixin from '@/common/humanise.mixin'

export default {
  components: { vSelect, BIcon, BIconArrowClockwise },
  mixins: [humaniseMixin],
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      form: {
        searchTerm: { required, minLength: minLength(7) },
      },
    }
  },
  data() {
    return {
      isLoading: false,
      form: {
        searchTerm: '',
      },
      searched: false,
      searchResult: null,
      resultType: null,
      psStatusCheckData: [],
    }
  },
  computed: {

  },
  async mounted() {

  },
  methods: {
    copyBlock(item) {
      navigator.clipboard.writeText(JSON.stringify(item, undefined, 2))

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Raw Copied',
          icon: 'BellIcon',
        },
      })
    },
    canSplit(str, token) {
      return (str || '').split(token).length > 1
    },
    copyNoResult(searchTerm) {
      const clipboardContent = `${searchTerm}\nStatus: Transaction not found\n\n`

      navigator.clipboard.writeText(clipboardContent)

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Telegram Text Copied',
          icon: 'BellIcon',
        },
      })
    },
    copyCardContent(obj, txnType, psStatusCheckIndex = null) {
      console.log('obj', obj)
      const errorMessageObj = obj.error_message

      let txnStatus = txnType
      let invoiceRef

      if (txnType === 'Transaction') {
        txnStatus = obj.invoice_status
        invoiceRef = obj.invoice_ref
      } else if (txnType === 'Payout') {
        txnStatus = obj.transaction_status
        invoiceRef = obj.external_invoice_ref
      }

      let clipboardContent = ''

      if (txnStatus === 'FAILED') {
        if (txnType === 'Transaction') {
          const debitStatus = obj.fpx_debit_status
          const debitAuthCode = obj.fpx_debit_auth_code

          if (debitStatus && debitAuthCode) {
            clipboardContent = `${invoiceRef}\nStatus: ${txnStatus}\nReason: ${debitAuthCode} -  ${debitStatus}\n\n`
          } else {
            clipboardContent = `${invoiceRef}\nStatus: ${txnStatus}\nReason: Unknown\n\n`
          }

          console.log(clipboardContent)

          navigator.clipboard.writeText(clipboardContent)
        } else if (txnType === 'Payout') {
          const msg = errorMessageObj.error_message
          if (this.canSplit(msg, '|')) {
            if (errorMessageObj.error_code === 'U128') {
              clipboardContent = `${invoiceRef}\nStatus: ${txnStatus}\nReason: ${errorMessageObj.error_code} - ${this.translateText(errorMessageObj.error_message.split('|')[0])}\n\n`
            } else {
              clipboardContent = `${invoiceRef}\nStatus: ${txnStatus}\nReason: ${errorMessageObj.error_code} - ${this.translateText(errorMessageObj.error_message.split('|')[2])}\n\n`
            }
            console.log(clipboardContent)
            // this.doCopy(clipboardContent, 'Telegram Message')
            navigator.clipboard.writeText(clipboardContent)
          } else {
            clipboardContent = `${invoiceRef}\nStatus: ${txnStatus}\nReason: ${errorMessageObj.error_code} - ${this.translateText(errorMessageObj.error_message)}\n\n`

            console.log(clipboardContent)
            // this.doCopy(clipboardContent, 'Telegram Message')
            navigator.clipboard.writeText(clipboardContent)
          }
        } else {
          if (txnType === 'Transaction') {
            clipboardContent = `${invoiceRef}\nStatus: ${txnStatus}\nReason: - \n\n`
          } else if (txnType === 'Tayout') {
            clipboardContent = `${invoiceRef}\nStatus: ${txnStatus}\nReason: ${this.translateText(errorMessageObj.description)}\n\n`
          }

          clipboardContent = `${invoiceRef}\nStatus: ${txnStatus}\nReason: ${this.translateText(errorMessageObj.description)}\n\n`
          console.log(clipboardContent)

          navigator.clipboard.writeText(clipboardContent)
        }
      }
      if (txnStatus === 'PENDING') {
        clipboardContent = `${invoiceRef}\nStatus: ${txnStatus}\n\n`
        // this.doCopy(clipboardContent, 'Telegram Message')

        navigator.clipboard.writeText(clipboardContent)
      }
      if (txnStatus === 'SUCCESS') {
        clipboardContent = `${invoiceRef}\nStatus: ${txnStatus}\n\n`
        // this.doCopy(clipboardContent, 'Telegram Message')
        navigator.clipboard.writeText(clipboardContent)
      }

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Telegram Text Copied',
          icon: 'BellIcon',
        },
      })
    },
    translateText(str) {
      let newText = ''
      switch (str) {
        case 'confident level name low than threshold':
          newText = 'Confident level name low than threshold'
          break

        case 'account verification failed please try again':
          newText = 'Account verification failed'
          break

        case ' Cause: Beneficiary bank’s technical problem/ error.':
          newText = 'Beneficiary bank’s technical problem/ error.'
          break

        case ' Cause: Transaction rejection by Paynet.':
          newText = 'Transaction rejection by Paynet.'
          break

        case ' Cause: Uncaught Cause':
          newText = 'Uncaught Cause'
          break

        case 'DUPLICATE_PAYOUT_TRANSACTION':
          newText = 'Duplicate payout transaction'
          break

        case 'duplicate transaction':
          newText = 'Duplicate transaction'
          break

        case 'UNABLE_TO_GET_TOKEN_ACCESS':
          newText = 'Unable to get access token'
          break

        default:
          newText = str
          break
      }
      console.log(newText)
      return newText
    },
    triggerCallbackData(invoiceNo) {
      console.log(invoiceNo)

      const payload = {
        invoice_nos: [invoiceNo],
      }

      this.$store.dispatch('general/omniCallbackBulkCallback', payload).then(res => {
        if (res.data.response_code === 2000) {
          this.$swal.fire({
            title: 'Successfully Triggered',
            text: 'Callback Triggered',
            icon: 'success',
          })
        } else {
          const desc = `<h5>Description</h5><p>${res.data.description}</p><h5>Breakdown</h5><p>${res.data.breakdown_errors}</p>`
          this.$swal.fire({
            title: `Error - ${res.data.response_code}`,
            html: desc,
            icon: 'error',
          })
        }
      })
    },
    getPSTxnStatus(txnInvoiceNo) {
      // console.log(txnInvoiceNo)
      this.isLoadingPSTxn = true
      const payload = {
        transaction_invoice_no: txnInvoiceNo,
      }

      this.psStatusCheckData = null
      this.$store.dispatch('adminCustomerBills/fpxFailedReason', payload).then(res => {
        if (res.data.response_code === 2000) {
          // this.psStatusCheckData = {
          //   invoice_status_id: customerBills.invoice_status_id,
          //   invoice_ref: customerBills.invoice_ref,
          //   ...res.data.data,
          // }
          // this.copyAsTelegram(this.psStatusCheckData)
          // console.log(res.data)

          this.isLoadingPSTxn = false
        }
      })
    },
    isValidJSON(str) {
      try {
        JSON.parse(str)
        console.log(true)
        return true
      } catch (e) {
        console.log(false)
        return false
      }
    },
    async resetForm() {
      this.form.searchTerm = 0
      this.searched = false
      this.searchResult = null
    },
    closeModal() {
      this.resetForm()
      this.$emit('escaped-click')
    },
    async search() {
      const valid = await this.v$.form.$validate()

      if (!valid) {
        return
      }

      const payload = {
        invoice_no: this.form.searchTerm,
      }

      this.isLoading = true

      await this.$store.dispatch('general/omniCheckInvoice', payload).then(res => {
        // console.log(res.data)
        this.searched = true

        if (res.data.response_code === 2000) {
          this.searchResult = res.data.data
          // this.resultType = res.data.data.state
        } else {
          this.searchResult = []
        }

        this.isLoading = false
      })
    },
  },
}
</script>

<style>

</style>
